import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularElementType } from '@syncfusion/ej2-angular-base';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SharedserviceService } from './sharedservice.service';
@Injectable({
    providedIn: 'root'
})
export class CommonService {
  public userimgsrc:any='';
  public userimage:any='';

    showSelf: boolean = false;
    showSelfBlock: boolean = false;
    flightBlockedDetails: any;
    flightdetails: any;
    public osname:any;
    public modalshow:boolean=false;
    public clicked_inventory:any;
    public user:any;
    public user_id: any;
    public b_update:boolean=false;
    public user_balance: any;
    public imageTrue:any={};
    public bgBner:boolean=false;
    public headBner:boolean=false;
    public compDtl:any;
    public acDtl:any;
    public unicId:any;
    public searchedflight:any;
    public searchedflightticketusertype:any;
    public searchedflight_detail:any;
    public srcimagepath:any;
    public bannerTrue:any;
    // public headBner:any;
    public inventoryview_searchdata:any;
  getData: any;
    constructor(private router: Router,private httpClient:SharedserviceService,private toastr: ToastrManager) { 
        this.user = JSON.parse(localStorage.getItem("userAgency_agarwalair"));
        if (this.user != null) {
            this.user_id = this.user.id;
          }
        this.imageTrue['headvalue']='0';

          if(this.imageTrue.headvalue==1){
            this.bgBner=true;
          }else{
            this.bgBner=false;
          }
          this.osname=this.getOS();
    }
    getToken(): string { 
        return window.localStorage['tokenAgency_agarwalair'] 
    }
    saveToken(token: string) { 
        window.localStorage['tokenAgency_agarwalair'] = token 
    }
    destroyToken() { 
        window.localStorage.removeItem('tokenAgency_agarwalair'); 
        window.localStorage.removeItem('userAgency_agarwalair')
    }
    getUser(): string { 
        return window.localStorage['userAgency_agarwalair'] 
    }
    saveUser(user: string) { 
        window.localStorage['userAgency_agarwalair'] = user; 
    }

    reloadbalance(){
        const self = this;
        var requestdata={
          user_id:this.user_id
        }
        this.httpClient.post('v1/vendor_account/get_balance', requestdata).subscribe((res: any) => {
           if (res.replyCode == "success") {
             console.log("asdasdasdas",res.data)
             self.user_balance=res.data.available_balance;
             self.b_update=true;
             var updatedData = JSON.parse(localStorage.getItem('userAgency_agarwalair'));
             console.log("first Data",updatedData);
             
              updatedData.available_balance=self.user_balance;
             console.log("second Data",updatedData);
     
              localStorage.removeItem('userAgency_agarwalair');
              setTimeout(() => {
               localStorage.setItem('userAgency_agarwalair',JSON.stringify(updatedData));
              }, 100);
            }
          }, err => {
        });
      }

      userinfo() {
        var request = { }
        this.httpClient.post('v1/users/view_profile', request).subscribe(res => {
          if (res.replyCode == "success") {
            var UserDataShow = res.data;
            this.userimgsrc = res['imageurl'];
            this.userimage = this.userimgsrc + "/" + UserDataShow.user_image;
            }
          }, err => {
            this.toastr.errorToastr(err['error']['message'], 'Error');
          });
    
          return this.userimage;
          
      }  

    getOS() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;
      
        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
          os = 'Linux';
        }
      
        return os;
      }  

      checkHeader(event) {
        var headData = { headvalue: 1 }
        if (event.checked == true) {
          sessionStorage.setItem("headerTrue", JSON.stringify(headData));
          this.bannerTrue = JSON.parse(sessionStorage.getItem("headerTrue"));
          if (this.bannerTrue.headvalue == 1) {
            this.headBner = true;
            event.checked = true;
          }
          else {
            event.checked = false;
            this.headBner = false;
          }
        }
        else {
          sessionStorage.removeItem("headerTrue");
          this.headBner = false;
          event.checked = false;
        }
      }

    imgBanner(event){
        var bgData={headvalue:1}
        if(event.checked==true){
            sessionStorage.setItem("imageBg",JSON.stringify(bgData));
            this.imageTrue= JSON.parse(sessionStorage.getItem("imageBg"));
            if(this.imageTrue.headvalue==1){
            // 
//console.log("checkvalue session::::::::",this.imageTrue.headvalue);
            this.bgBner=true;
            event.checked=true;
          }
          else{
              event.checked=false;
              this.bgBner=false;
            }
        }
        else{
          sessionStorage.removeItem("imageBg");
          this.bgBner=false;
          event.checked=false;
        }
      }

    setinventory_searchdata(data,date){
        //
//console.log("asasdawdkahsldjkgashldgashlkgdasghldhlasgdkhasg",data)
        this.inventoryview_searchdata=data;
        localStorage.setItem("inventory_flight_id",data.flight_id);
        localStorage.setItem("inventory_data",JSON.stringify(data));
        localStorage.setItem("inventory_d_date",date);
       //onsole.log("common service ::: ",this.inventoryview_searchdata);
        // this.router.navigateByUrl('master/inventory-view'+'#'+date);
        this.router.navigate(['/master/inventory-view']);
    }
    setinventory_searchdata1(data,id,date){
        localStorage.setItem("inventory_flight_id",id);
        localStorage.setItem("inventory_d_date",date);
        localStorage.setItem("inventory_data",JSON.stringify(data));
       //onsole.log("common service ::: ",this.inventoryview_searchdata);
        this.router.navigate(["/master/inventory-view"]);
        // this.router.navigateByUrl("master/inventory-view"+'#'+date);
    }
    searchflightdetail(data){
        const self=this;
        // this.httpClient.post('v1/search_flight/search_ticket',data).subscribe(res => {
        //     this.searchedflight=res;
        //     
//console.log("searched flight ::: ",this.searchedflight);
            
        // });
        this.httpClient.post('v1/search_flight/search_ticket', data).subscribe((res: any) => {
             if (res.replyCode == "success" && res.data!= '' ) {
                self.searchedflight=res.data;
                self.srcimagepath=res.imgBasePath;
                
//console.log("searched flight ::: ",self.searchedflight);
                sessionStorage.setItem("flights", JSON.stringify(self.searchedflight) );
                sessionStorage.setItem("imagepath", JSON.stringify(self.srcimagepath) );
                self.router.navigateByUrl("search-detail");
             }else{
                sessionStorage.removeItem("flights");
                sessionStorage.removeItem("imagepath");
                self.toastr.errorToastr("Result Not Found","Error")
             }        
          },err=>{
              this.toastr.errorToastr(err.error.replyMsg,"Error");
          });
    }
    searchflightticket(data){
        const self=this;
        this.httpClient.post('v1/search_flight/search_ticket', data).subscribe((res: any) => {
             if (res.replyCode == "success" && res.data!= '' ) {
                this.searchedflightticketusertype=res.data[0].inventory_user_Type
             }
             else{
                sessionStorage.removeItem("flights");
                sessionStorage.removeItem("imagepath");
                self.toastr.errorToastr("Result Not Found","Error")
             }        
          });
    }
    searchflightdetails(data){
        const self=this;
        this.httpClient.post('v1/search_flight/search_ticket', data).subscribe((res: any) => {
             if (res.replyCode == "success" && res.data!= '' ) {
                self.searchedflightticketusertype=res.data[0].inventory_user_Type
                self.searchedflight=res.data;
                self.srcimagepath=res.imgBasePath;
                
//console.log("searched flight ::: ",self.searchedflight);
                sessionStorage.setItem("flights", JSON.stringify(self.searchedflight) );
                sessionStorage.setItem("imagepath", JSON.stringify(self.srcimagepath) );
                self.router.navigateByUrl("search-detail");
             }
             else{
                sessionStorage.removeItem("flights");
                sessionStorage.removeItem("imagepath");
                self.toastr.errorToastr("Result Not Found","Error")
             }        
          });
    }
    searchedflightdetail(data){
      console.log("Data Check for Booking Token id",data);
        const self=this;
        this.httpClient.post('v1/search_flight/search_details', data).subscribe((res: any) => {
             if (res.replyCode == "success" && res.data!= '' ) {
                self.searchedflight_detail=res.data;
                self.searchedflight_detail.booking_token=data.booking_token;
                self.searchedflight_detail.inventory_user_Type=data.inv_user;
                self.srcimagepath=res.imgBasePath;
                sessionStorage.setItem("searchedflights_detail", JSON.stringify(self.searchedflight_detail) );
                sessionStorage.setItem("imagepath", JSON.stringify(self.srcimagepath) );
                self.router.navigateByUrl("detail");
             }else{
                sessionStorage.removeItem("searchedflights_detail");
                sessionStorage.removeItem("imagepath");
                self.toastr.errorToastr("Result Not Found","Error")
             }        
          });
    }
    isTokenValid() {
        this.destroyToken();
        localStorage.removeItem("tokenAgency_agarwalair");
        localStorage.removeItem("userAgency_agarwalair");
        this.router.navigateByUrl('/login');
    }
    onLogout() {
        this.destroyToken();
        // localStorage.clear();
        // sessionStorage.clear();
        localStorage.removeItem("tokenAgency_agarwalair");
        localStorage.removeItem("userAgency_agarwalair");
        this.router.navigateByUrl('/auth/login');
    }
    
    // accountDtl(data){
    //     this.unicId={
    //         share_inventorie_id:data.share_inventorie_id,
    //         share_to_user_id:data.share_to_user_id,
    //         amount:data.credit_limit
    //     }        
    //     sessionStorage.setItem("account_detail_data",this.unicId)  
    //     }          
    //     var requestdata={
    //         share_inventorie_id:data.share_inventorie_id,
    //         share_to_user_id:data.share_to_user_id,
    //         page_limit: "",
    //         page_no: "",
    //         search_value: "",
    //         order_key: "",
    //         order_value: ""
    //     }
    //     const self=this;
    //     this.httpClient.post('v1/account/company_account_details', data).subscribe((res: any) => {
    //         if (res.replyCode == "success" && res.data!= '' ) {
    //            self.acDtl=res.data;
    //            self.compDtl=res.company_details;
    //         //    
//console.log("searched flight ::: ",self.searchedflight);
    //            self.router.navigateByUrl("dashboard/account-balance");
    //         }else{
    //            self.toastr.errorToastr("Result Not Found","Error")
    //         }        
    //      },err=>{
    //          this.toastr.errorToastr(err.error.replyMsg,"Error");
    //      });       
    // }


}