// //*for live api use uncomment============>>>>/*
export const environment = {
  production: false,
  apiUrl:'https://partnerapi.fareboutique.com/v1/',
  url:'https://partnerapi.fareboutique.com/'
};


// //*for Demo api use uncomment============>>>>*/
// export const environment = {
//   production: false,
//   apiUrl:'https://demopartnerapi.fareboutique.com/v1/',
//   url:'https://demopartnerapi.fareboutique.com/'
// };


// //*for Dev api use uncomment============>>>>*/
// export const environment = {
//   production: false,
//   apiUrl:'https://devpatnerapi.fareboutique.com/v1/',
//   url:'https://devpatnerapi.fareboutique.com/'
// };

